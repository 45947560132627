import React, { createContext, useContext, useState, useEffect } from "react"
import { Spinner, useUserLogin, ApiFactory } from "@collapick/utils"

const UserContext = createContext()

const DEBUG = true
const debugConsole = (arg1, arg2) => {
    if (DEBUG) {
        console.info(arg1, arg2)
    }
}

/**
 *
 * @param {*} token
 * @param {*} setIsAuthenticated
 * @param {*} setIsValidating
 * @param {*} LoginApi
 * @param {*} clearUserData
 */
const isTokenValid = (token, isValidating, setIsAuthenticated, setIsValidating, LoginApi, clearUserData) => {
    debugConsole("Validating token...", { token })
    if (!isValidating) {
        setIsValidating(true)
        if (token != null && token.length > 0) {
            let authResult = false
            debugConsole("Authenticating token...")
            return LoginApi.isTokenValid(token)
                .then((res) => {
                    authResult = res
                    return
                })
                .catch((err) => {
                    console.error(err)
                })
                .then(() => {
                    debugConsole("Authentication result: " + authResult)
                    if (!authResult) {
                        clearUserData()
                    }
                    setIsAuthenticated(authResult)
                    setIsValidating(false)
                    return
                })
        } else {
            debugConsole("Context Token was empty or null; not authenticated.")
            clearUserData()
            setIsAuthenticated(false)
            setIsValidating(false)
        }
    }
}

/**
 * Provider for AppUser and loginInfo
 * Token can be passed via props.token
 * @param {*} props
 */
const UserProvider = (props) => {
    const { token, setToken, user, setUser, roles, setRoles, clearUserData } = useUserLogin()

    const { LoginApi } = ApiFactory(token, null, true, false)

    const [isAuthenticated, setIsAuthenticated] = useState(false)
    const [isValidating, setIsValidating] = useState(false)

    // If passing from a prop to React App wrapper.
    if (props.token && props.token !== token) {
        setToken(props.token)
    }

    debugConsole("UserProvider initial", {
        token,
        user,
        roles,
        isAuthenticated,
        isValidating
    })
    useEffect(() => {
        debugConsole("UserContext useEffect")
        isTokenValid(token, isValidating, setIsAuthenticated, setIsValidating, LoginApi, clearUserData)
    }, [token]);

    
    //FIXME: jostain  authenticated on false vaikka se onkin true. Johtuu reactin muistinkäytöstä. Ei varmaan aikaisemmin huomattu tuota. Storybookin kanssa tulee ongelmia
    //FIXME: Tää pitäisi tehdä jotenkin toisin
    let authenticated = false;
    if(isAuthenticated){
        authenticated = true;
    }else if(isAuthenticated == false && token != null){
        isTokenValid(token, isValidating, setIsAuthenticated, setIsValidating, LoginApi, clearUserData)
    }

    let values = {
        token,
        setToken,
        user,
        setUser,
        roles,
        setRoles,
        clearUserData,
        isAuthenticated: authenticated,
        isValidating
    }

    return (
        <UserContext.Provider value={values}>
            {isValidating ? <Spinner enabled={isValidating} /> : props.children}
        </UserContext.Provider>
    )
}

/**
 * ContextWrapper that warns if used outside Provider
 */
const useUserContext = () => {
    const context = useContext(UserContext)
    if (context === undefined) {
        throw new Error("useUserContext must be used within a UserProvider")
    } else {
        return context
    }
}

export { UserProvider, useUserContext, UserContext }

export default UserContext
