import React, { createContext, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { green, orange, red, yellow } from "@material-ui/core/colors";
import createMuiTheme from "@material-ui/core/styles/createMuiTheme";


//Default stored data is null in context
export const ThemeContext = createContext(null);

//Logic for context to initilize
export const ThemeContextProvider = ({ children }) => {


  const [primary, setPrimary] = useState({
    main: "#66ba12",
    light: "#9aed4f",
    dark: "#2e8900",
    contrastText: '#ffffff'
  })
  const [secondary, setSecondary] = useState({
    /*  main: "#ba8936",
      light: "#efb964",
      dark: "#865c01",
      contrastText: '#ffffff'*/
    contrastText: "#66ba12",
    light: "#9aed4f",
    dark: "#2e8900",
    main: '#ffffff'
  })

  const toggleColor = () => {
    setPrimary(secondary);
    setSecondary(primary);
  }

  let themeData = createMuiTheme({
    palette: {
      primary: primary,
      secondary: secondary
    },
    typography: { useNextVariants: true },

  });
  //overrides
  themeData.overrides = {
    MuiExpansionPanelDetails: {
      root: {
        [themeData.breakpoints.down('sm')]: {
          padding: "4px 10px 10px"
        }
      }
    },
    MuiDialogContent: {
      root: {
        [themeData.breakpoints.down('sm')]: {
          padding: "8px 10px"
        }
      },

    },
    MuiDialog: {
      paper: {
        [themeData.breakpoints.down('sm')]: {
          margin: "0px"
        }
      },
      paperFullWidth: {
        [themeData.breakpoints.down('sm')]: {
          width: "calc(100% - 30px)"
        }
      }
    }
  }


  const store = {
    themeData: themeData,
    toggleColor: toggleColor,
  };

  //Return provider to access intilaised data
  return <ThemeContext.Provider value={store}>{children}</ThemeContext.Provider>
}

