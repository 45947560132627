import React, { useState, useEffect } from 'react';
import MaterialTable, { MTableBodyRow, MTableCell, MTableBody } from 'material-table';
import CachedIcon from '@material-ui/icons/Cached';
import { useTranslation } from 'react-i18next';
import { ArrowUpward } from '@material-ui/icons'
import { green } from '@material-ui/core/colors';
import { Paper } from '@material-ui/core'
import TableIcons from "./TableIcons";
import { LocalStorageHelper } from '@collapick/utils';
import { makeStyles, withStyles } from '@material-ui/core/styles';


const baseStorage = {
    hiddenColumns: []
}

const tableIcons = TableIcons;

const useStyles = makeStyles(() => ({
    row: {
        height: "20px"
    },
    cell: {
        paddingTop: '0 !important',
        paddingBottom: '0 !important',
    },
    body: {

    },
    table: {
        '&.MuiPaper-elevation2': {
            boxShadow: "none"
        }
    }
}))


const CustomMaterialTable = (props) => {


    const { onChangeColumnHidden, remberColumns, columns, title, customIcons, columnsHide, clearCache } = props;
    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const { t } = useTranslation();
    const classes = useStyles()


    let newProps = { ...props, columns };
    if (typeof onChangeColumnHidden == 'undefined' && remberColumns) {
        newProps.onChangeColumnHidden = (column) => {
            columnsHide(column)
        }

        if (columns?.hiddenColumns?.length > 0) {
            newProps?.columns.forEach(c => {
                if (columns?.hiddenColumns.indexOf(c.field) > -1) {
                    c.hidden = true;
                }
            })

            if (newProps.actions == null) {
                newProps.actions = [];
            }

            let clear = newProps.actions.find(o => o.key == "clear")
            if (clear == null) {
                newProps.actions.push(
                    {
                        key: "clear",
                        icon: () => {
                            return <CachedIcon />
                        },
                        tooltip: 'Clear cache',
                        isFreeAction: true,
                        onClick: clearCache
                    }
                )
            }

        }




    }

    if (typeof newProps.localization == 'undefined') {
        newProps.localization = {
            header: {
                actions: t('Actions')
            },
            toolbar: {
                exportTitle: t('export'),
                exportCSVName: t('export_as_csv'),
                exportPDFName: t('export_as_pdf'),
                searchTooltip: t('search'),
                searchPlaceholder: t('search'),
                searchAriaLabel: t('search'),
                clearSearchAriaLabel: t('clear_search'),
                exportAriaLabel: t("export"),
                showColumnsTitle: t("show_columns"),
                showColumnsAriaLabel: t("show_columns"),
                addRemoveColumns: t("addRemoveColumns")
            }
        }
    }



    if (customIcons) {
        newProps.icons.SortArrow = React.forwardRef((props, ref) => {
            return <ArrowUpward style={{ color: green[500], fontSize: "20px", opacity: 1 }} {...props} ref={ref} />
        })
    }

    return (
        <>
            <MaterialTable
                components={{
                    Container: props => <Paper {...props} elevation={0} />,
                    Row: props => {
                        return <MTableBodyRow className={classes.row} {...props} />
                    },
                    Cell: props => {
                        return <MTableCell className={classes.cell} {...props} />
                    },
                    Body: props => {
                        return <MTableBody className={classes.body} {...props} />
                    }
                }}
                className={classes.table}
                {...newProps} />
        </>
    )
};

CustomMaterialTable.defaultProps = {
    remberColumns: true,
    customIcons: true,
    columns: [],
    icons: tableIcons
};
export default CustomMaterialTable