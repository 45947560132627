import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ConfirmDialog = (props) => {
  const  {open, setOpen, content, title, disagreeCallback, agreeCallback, agree, disagree } = props;

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
    
      <Dialog
        maxWidth="sm"
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {content}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={disagreeCallback} color="primary">
            {disagree}
          </Button>
          <Button onClick={agreeCallback} color="primary" variant="contained">
            {agree}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

ConfirmDialog.defaultProps = {
    open:false,
    title: "Title",
    content: "Content",
    setOpen: console.log,
    disagreeCallback: console.log,
    agreeCallback: console.log,
    disagree: "Disagree",
    agree: "Agree"
}

export default ConfirmDialog;