import React from 'react'
//import { Api, useUser } from '@collapick/utils';
import useApi from './useApi'

const useMaterialApi = () => {
    var baseUrl = "http://localhost:3000/api";

    if (window.BASEURL != null) {
        // Getter from window scope
        baseUrl = window.BASEURL;
    }

    const MaterialApi = useApi().ItemApi

    // const MaterialApi = Api(baseUrl + "/item", token);

    const find = (filter) => {
        return MaterialApi.find({ filter: JSON.stringify(filter) }).then(result => {

            return result;
        })
    }

    const materialTableAjax = (params, roles) => {
        let skip = params.page * params.pageSize;
        let query = {
            where: {
                itemType: 'SALDO-MATERIAL'
            },
            limit: params.pageSize,
            skip,
            order: "name DESC"
        }

        if (params?.orderBy?.field) {
            query.order = params?.orderBy?.field + " " + params.orderDirection
        }

        if (params.filters.length > 0) {
            params.filters.forEach(f => {
                if (f.column.field == 'CustomerItemCode') {
                    query.where.itemObject = {
                        json: {
                            "customerItemCode": { like: "%" + f.value + '%' }
                        }
                    }
                }
                else if (f.column.field == 'CustomerItemName') {
                    query.where.itemObject = {
                        json: {
                            "customerItemName": { like: "%" + f.value + '%' }
                        }
                    }
                }
                else if (f.column.field == 'itemCode') {
                    query.where[f.column.field] = { like: f.value + "%" }
                }
                else {
                    query.where[f.column.field] = { like: "%" + f.value + "%" }
                }
            })
        }

        let isAdmin = false;
        let customIds = [];
        roles?.forEach(r => {
            if (r.name == 'admin') {
                isAdmin = true;
            } else {
                customIds.push(r.name);
            }
        });

        if (isAdmin == false) {
            query.where.customId = "-1"

            if (customIds.length > 0) {
                query.where.customId = { inq: customIds }
            }
        }



        let finalResult = {
            page: params.page,
            data: [],
            totalCount: 0
        }

        return MaterialApi.count({ where: query.where }).then(res => {
            finalResult.totalCount = res.count
            return MaterialApi.find({ filter: JSON.stringify(query) })
        }).then(result => {
            const key = 'itemCode';
            const arrayUniqueByKey = [...new Map(result.map(items =>
                [items[key], items])).values()];
            finalResult.data = arrayUniqueByKey;
            return finalResult
        })
    }


    return {
        find,
        materialTableAjax
    }

}

export default useMaterialApi