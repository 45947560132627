import { Spinner } from "@collapick/utils/dist/Spinner"
import React, { useEffect } from "react"
import { Redirect, useHistory } from "react-router-dom"
import { useUserContext } from '../Context/UserContext'

import useApi from "../../../hooks/useApi"
import useRoutes from "../../../hooks/useRoutes"

const LogoutView = (props) => {
    const { token, clearUserData, isAuthenticated } = useUserContext()
    const { LoginApi } = useApi()
    const history = useHistory();
    const { PATH } = useRoutes();



    useEffect(() => {
        LoginApi.logout(token)
            .catch((err) => {
                console.error(err)
            })
            .finally(() => {
                clearUserData()
                history.push(PATH.login)
            })
    }, []);

    if (!isAuthenticated) {
        clearUserData()
        return <Redirect to={PATH.login} />
    }

    return <Spinner />
}

export default LogoutView
