import React, {useContext} from 'react';
import { fade, makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import InputBase from '@material-ui/core/InputBase';
import Badge from '@material-ui/core/Badge';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MailIcon from '@material-ui/icons/Mail';
import NotificationsIcon from '@material-ui/icons/Notifications';
import MoreIcon from '@material-ui/icons/MoreVert';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { useTranslation } from 'react-i18next';
import AddIcon from '@material-ui/icons/Add';
import { useHistory } from 'react-router-dom';
import { ThemeContext } from '../../apps/FormProcessApp/Context/ThemeContext';



const ProfileMenu = (props) => {
	const { anchorRef, setAnchorRef } = props;

	const themeContext = useContext(ThemeContext)
	const { t } = useTranslation();
	const history = useHistory();

	const isMenuOpen = Boolean(anchorRef);



	const handleMobileMenuClose = () => {
		setAnchorRef(null);
	};

	const changeColor = () => {
		themeContext.toggleColor();
	}



	const mobileMenuId = 'primary-search-account-menu-mobile';


	return (
		<>
			<Menu
				anchorEl={anchorRef}
				anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
				id={mobileMenuId}
				keepMounted
				transformOrigin={{ vertical: 'top', horizontal: 'right' }}
				open={isMenuOpen}
				onClose={handleMobileMenuClose}
			>
				<MenuItem onClick={() => history.push('/logout')}>
					<IconButton
						aria-label="account of current user"
						aria-controls="primary-search-account-menu"
						aria-haspopup="true"
						color="inherit"
					>
						<ExitToAppIcon />
					</IconButton>
					<p>{t('Exit')}</p>
				</MenuItem>
				
			</Menu>
		</>
	)
}

ProfileMenu.defaultProps = {
	anchorRef: null,
	setAnchorRef: console.log,

};

export default ProfileMenu;