import React, { useContext, useState } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import HomeIcon from '@material-ui/icons/Home';
import { useHistory } from 'react-router-dom';
import { Button, Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import Typography from "@material-ui/core/Typography";
import { useUser } from '@collapick/utils';
import AccountCircle from '@material-ui/icons/AccountCircle';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import ProfileMenu from './ProfileMenu';
import { makeStyles } from "@material-ui/core"
import { BorderColor } from '@material-ui/icons';
import logo from './Picture/logo.svg'

const useStyles = makeStyles((theme) => ({
    root: {
        '&.MuiPaper-elevation4': {
            boxShadow: "none"
        }
    },
    image: {
        width: "200px",
        height: "auto",
        //    backgroundColor: "white"
    }
}));


const Header = (props) => {

    const { t } = useTranslation();
    let { title, headerItem, historyAction, logout } = props;
    const history = useHistory();
    const { setUser, setToken } = useUser();
    const [profileOpen, setProfileOpen] = useState(false)
    const [profileAnchorEl, setProfileAnchorEl] = useState(null)
    const classes = useStyles()

    if (!headerItem) {
        headerItem = (
            <Grid item>
                <Button>
                    {''}
                </Button>
            </Grid>
        )
    }


    const logoutAction = () => {
        setToken(null);
        setUser(null);
        history.push('/')
    }

    const openProfile = (event) => {
        setProfileOpen(true);
        setProfileAnchorEl(event.currentTarget);
    }

    return (
        <>
            <AppBar position="static" color="secondary" className={classes.root}>
                <Toolbar>
                    <Grid
                        justify="space-evenly"
                        container
                        wrap={"nowrap"}
                        item
                        xs={12}
                        alignItems="center"
                        spacing={2}
                    >
                        <Grid item xs={2}>
                            <img className={classes.image} src={logo} />{/*"https://www.byniemi.fi/wp-content/themes/byniemi/images/logo.png" />*/}
                            {historyAction && (
                                <IconButton onClick={() => historyAction()} edge="end" color="inherit"
                                    aria-label="menu">
                                    <ArrowBackIcon />
                                </IconButton>
                            )}
                        </Grid>


                        {title && (
                            <Grid item xs={8}>
                                <Grid container justify="center">
                                    <Grid item>
                                        <Typography variant="h6">{title}</Typography>
                                    </Grid>
                                </Grid>

                            </Grid>
                        )}

                        {logout ? (
                            <>
                                <Grid item xs={2}>
                                    <Grid container justify="flex-end">
                                        <Grid item>
                                            <IconButton onClick={openProfile} edge="end" color="inherit"
                                                aria-label="menu">
                                                <AccountCircle />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <ProfileMenu anchorRef={profileAnchorEl} setAnchorRef={setProfileAnchorEl} />
                            </>
                        ) : <Grid item xs={2} />}
                        {/* Offsets the login title to center since logout is not present in login */}
                    </Grid>
                </Toolbar>
            </AppBar>
        </>
    )
};

Header.defaultProps = {
    title: 'Example',
    historyAction: null,
    logout: true,
};

export default Header