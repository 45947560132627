import React from "react"
import { Redirect } from "react-router-dom"
import { useUserContext } from '../Context/UserContext'
import { useTranslation } from "react-i18next"

import { Login } from "@collapick/utils"
import useRoutes from "../../../hooks/useRoutes"

import Header from "../../../components/Header/Header"
import useApi from "../../../hooks/useApi"
import { Container, makeStyles, Grid } from "@material-ui/core"
import logo from '../../../components/Header/Picture/logo.svg'

const useStyles = makeStyles((theme) => ({
    typography: {
        textAlign: "center",
        margin: "30px 0px"
    },
    image: {
        width: "200px",
        height: "auto",
        margin: "30px 0px"
    },
    imageContainer: {
        textAlign: "center"
    }

}));

const LoginView = (props) => {
    const { t } = useTranslation()
    const classes = useStyles();
    const { PATH } = useRoutes();
    const { LoginApi } = useApi()
    const userContext = useUserContext()

    // If user is authenticated, he may be redirected to root path.
    if (userContext.isAuthenticated) {
        return <Redirect to={PATH.root} />
    }

    const messages = {
        usernameLabel: t("Username or email"),
        passwordLabel: t("Password"),
        loginLabel: t('Sign in to application'),
        logoutLabel: t("logout")
    }

    const loginSuccessCallback = () => {
        // Login was successful
        console.info("loginSuccessCallback")
    }

    const loginFailedCallback = () => {
        // There was an error logging in.
        console.info("loginFailedCallback")
    }

    const userFilter = {
        include: [
            { relation: "roles" },
            {
                relation: "userBind",
                scope: {
                    include: [{ relation: "customers" }]
                }
            }
        ]
    }

    return (
        <>
            <Header title={t('Login')} logout={false} />
            {/* getUserInfo allows to retrieve more user info during login */}
            {/* useUserLogin stores login data to context */}
            <Container>
                <br />
                <br />

                <Login
                    LoginApi={LoginApi}
                    getUserInfo={userFilter}
                    useUserLogin={userContext}
                    messages={messages}
                    loginSuccessCallback={loginSuccessCallback}
                    loginFailedCallback={loginFailedCallback}
                />
                <Grid item xs={12} className={classes.imageContainer}>
                    <img className={classes.image} src={logo} />{/*"https://www.byniemi.fi/wp-content/themes/byniemi/images/logo.png" />*/}
                </Grid>
            </Container>
        </>
    )
}

export default LoginView
