import React from 'react'
import { PathParamSetter } from "@collapick/utils"
// Pages
import LoginView from "../apps/FormProcessApp/Views/LoginView"
//import LoginToApplicationView from '../apps/FormProcessApp/Views/LoginToApplicationView'
//import RootView from "../app/views/Root/RootView"
import { Redirect } from 'react-router-dom'
import LogoutView from '../apps/FormProcessApp/Views/LogoutView'
import Header from '../components/Header/Header'
//import Footer from '../components/Footer/Footer'
//import MainView from '../app/views/Main/MainView'
import SelectSiteView from '../apps/FormProcessApp/Views/SelectSiteView';

const PATH = {
    root: "/",
    login: "/login",
    logout: "/logout",
    mainRoute: "/main",
}



const createRoutes = () => {
    let routes = []
    try {
        routes = [
            {
                key: "ROOT",
                path: PATH.root,
                exact: true,
                render: (props) => {
                    return <Redirect to={PATH.mainRoute} />
                }
            },
            {
                key: "MAIN",
                path: PATH.mainRoute,
                private: true,
                exact: true,
                render: (props) => {
                    return (
                        <>
                            {/*     <Header />*/}
                            <SelectSiteView />
                        </>
                    )
                }
            },
            {
                key: "LOGIN",
                path: PATH.login,
                exact: true,
                render: (props) => {
                    return <LoginView {...props}></LoginView>
                }
            },
            {
                key: "LOGOUT",
                path: PATH.logout,
                exact: true,
                render: (props) => {
                    return (
                        <>
                            <LogoutView {...props} />
                        </>
                    )
                }
            }
        ]
    } catch (err) {
        console.warn("Routes array could not be build. Check routes object and any objects used in the routes array.")
        console.error(err)
    }
    return routes;
}




const useRoutes = () => {
    let routes = createRoutes();
    const { setPathParams, findPathAndSetParams, findRouteByKey } = PathParamSetter(routes)
    return {
        PATH,
        routes,
        setPathParams,
        findPathAndSetParams,
        findRouteByKey,
    }
};

export default useRoutes;
