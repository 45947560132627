import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Grid, Typography, TextField, Chip } from '@material-ui/core';
import Header from '../../../components/Header/Header';
import { useTranslation } from 'react-i18next';
import data from '../../../mockdata/output.json'
import CustomMaterialTable from '../../../components/Table/CustomMaterialTable';
import { PlayArrow } from '@material-ui/icons';
import DeleteIcon from '@material-ui/icons/Delete';
import ConfirmDialog from '../../../components/Dialogs/ConfirmDialog';
import useMaterialApi from '../../../hooks/useMaterialsApi'
//import useUser from '@collapick/utils/dist/useUser';
import { useUserContext } from '../Context/UserContext'
import { LocalStorageHelper } from '@collapick/utils';
import CachedIcon from '@material-ui/icons/Cached';

const generateLocalStorageKey = (title, columns) => {
    let key = title + "-CustomMaterialTable-" + columns?.length;
    return key
}

const SelectSiteView = (props) => {

    const history = useHistory();
    const { t } = useTranslation();
    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);

    const { user } = useUserContext();
    const [open, setOpen] = React.useState(false);
    const [data, setData] = React.useState([])
    const MaterialApi = useMaterialApi()
    const title = "Tuotteiden saldot"

    useEffect(() => {
        console.log(user)
    }, [])


    const ajax = (params) => {

        window.scrollTo(0, 0)
        let roles = user?.userBind?.appUser?.roles;
        return MaterialApi.materialTableAjax(params, roles)

    }


    let defaultColumns = []
    let isAdmin = false
    user?.userBind?.appUser?.roles?.forEach(r => {
        if (r.name == 'admin' || !r.name) {
            isAdmin = true;
        }
    })
    defaultColumns.push(
        { title: "Nimikekoodi", field: "itemCode" },
        { title: "Nimi", field: "name" },
        { title: "Nimi 2", field: "description" },
        {
            title: "Vapaa saldo", filtering: true, width: "5", field: "count", type: "numeric", cellStyle: { padding: 0, textAlign: 'center' }, headerStyle: { padding: 0, textAlign: 'center' }, render: (rowData) => {
                let backgroundColor = ""
                let color = ""
                if (rowData.count < 0) {
                    rowData.count = 0
                    //   backgroundColor = "#d32f2f"
                    //   color = "white"
                    color = "#d32f2f"
                }

                return <div style={{ /*backgroundColor: backgroundColor,*/ color: color, /*padding: "6px", verticalAlign: "middle",*/ fontWeight: 700 }} >{rowData.count} </div>
            }
        },
        { title: "Asiakas Id", field: "customId", hidden: true })
    if (isAdmin != true) {
        defaultColumns.push(
            {
                title: "Asiakkaan nimike", field: "CustomerItemCode", filtering: true, sorting: false, render: (rowData) => {
                    if (rowData?.itemObject?.customerData?.CustomerItemCode) {
                        return <>{rowData?.itemObject?.customerData?.CustomerItemCode}</>
                    } /*else {
                            return <>{rowData?.itemCode}</>
                        }*/
                }
            },
            {
                title: "Asiakkaan nimikkeen nimi", field: "CustomerItemName", filtering: true, sorting: false, render: (rowData) => {
                    if (rowData?.itemObject?.customerData?.CustomerItemName) {
                        return <>{rowData?.itemObject?.customerData?.CustomerItemName}</>
                    }
                }
            }
        )
    }
    /*{
        title: "Lapset", field: "childrens", filtering: false, render: (rowData) => {
        return rowData.itemObject?.children?.map(c => {
            return <div>{c.itemCode}</div>
        })
    }
    },
{
    title: "Asiakas", field: "customId", filtering: true,
},
{
    title: "Aktiivinen", field: "active", filtering: false, render: (rowData) => {
        console.log(rowData)
        return rowData.itemObject.active ? "Aktiivinen" : ""
    }
    }*/

    const columns = LocalStorageHelper.get(generateLocalStorageKey(title, defaultColumns)) || defaultColumns

    let overrideColumns = [...columns];
    overrideColumns.forEach(c => {
        if (c.field == 'count') {
            c.render = (rowData) => {
                let backgroundColor = ""
                let color = ""
                if (rowData.count < 0) {
                    rowData.count = 0
                    //backgroundColor = "#d32f2f"
                    //color = "white"
                    color = "#d32f2f"
                }

                return <div style={{ /*backgroundColor: backgroundColor, */color: color,/* padding: "6px", verticalAlign: "middle",*/ fontWeight: 700 }} >{rowData.count} </div>
            }
        } else if (c.field == 'CustomerItemCode') {
            c.render = (rowData) => {
                if (rowData?.itemObject?.customerData?.CustomerItemCode) {
                    return <>{rowData?.itemObject?.customerData?.CustomerItemCode}</>
                } /*else {
                    return <>{rowData?.itemCode}</>
                }*/
            }
        }
    })

    const clearCache = () => {
        LocalStorageHelper.remove(generateLocalStorageKey(title, defaultColumns))
        forceUpdate();
    }

    const columnsHide = (column) => {
        let newColumns = [...columns];
        let found = newColumns.find(c => c.field == column.field);
        if (found) {
            found.hidden = column.hidden;
        }
        LocalStorageHelper.remove(generateLocalStorageKey(title, defaultColumns))
        LocalStorageHelper.set(generateLocalStorageKey(title, defaultColumns), newColumns)
    }



    let options = {
        filtering: true,
        pageSize: 100,
        search: false,
        columnsButton: true,
        emptyRowsWhenPaging: false
    }

    const view = () => {
        setOpen(true);
    }

    const actions = [
        /*  {
              icon: () => {
                  return <PlayArrow />
              },
              tooltip: t('Varaa'),
              isFreeAction: false,
              onClick: view
          },*/
        {
            icon: () => {
                return <CachedIcon />
            },
            tooltip: t('clear_cache'),
            isFreeAction: true,
            onClick: clearCache
        }

    ]

    const dialogContent = () => {
        return (
            <>
                <div style={{ width: "400px" }}>
                    <Typography>Lisää tuote tilaukseen</Typography>
                    <TextField label={"Kappalemäärä"} />
                </div>

            </>
        )
    }

    return (
        <div>
            <Grid container spacing={2}>
                <Header title={t('Tuotteet')} />
            </Grid>
            <Grid container spacing={2}>

                <Grid item xs={12}>
                    <br />

                    <CustomMaterialTable id="dataTable" actions={actions} options={options} columns={overrideColumns} title={title} data={ajax} columnsHide={columnsHide} clearCache={clearCache} />
                </Grid>
            </Grid>
            <ConfirmDialog agree={" Lisää tilaukseen"} disagree={"Peruuta"} open={open} setOpen={setOpen} title={"Varaa tuote"} content={dialogContent()} disagreeCallback={() => { setOpen(false) }} />

        </div>
    );
};



SelectSiteView.defaultProps = {

}

export default SelectSiteView