import React, { useContext, useState, useEffect } from 'react';
import { Route, Switch, HashRouter, Redirect } from 'react-router-dom';
import { ThemeProvider } from "@material-ui/core/styles";
import { ErrorBoundary } from '@collapick/utils';
//import Provider, { ContextStorage } from "./Context/ContextStorage";
import { AppProvider } from "./Context/AppContext"
import useRoutes from '../../hooks/useRoutes'
import useAppRoutes from "../../hooks/useAppRoutes.js"
//import ROUTES, { RenderRoutes } from "./Routes";
import { ThemeContext, ThemeContextProvider } from './Context/ThemeContext';
import { UserProvider } from './Context/UserContext'
import { Grid } from '@material-ui/core';
import i18n from '../../locales/i18n';

//window.BASEURL = "https://api.vanha-parmaco.ponniste.com/api/"


const MainApp = () => {

    const themeContext = useContext(ThemeContext)
    const { routes } = useRoutes();
    const { RenderRoutes } = useAppRoutes();
    let result = null;
    result = (
        <ErrorBoundary>
            <UserProvider>
                <ThemeProvider theme={themeContext.themeData}>
                    <HashRouter>
                        <RenderRoutes routes={routes} />
                    </HashRouter>
                </ThemeProvider>
            </UserProvider>
        </ErrorBoundary>
    );

    return result;
};

const WrappedWithContext = () => {
    return (
        <AppProvider>
            <ThemeContextProvider>
                <MainApp />
            </ThemeContextProvider>
        </AppProvider>
    )
};


export default WrappedWithContext